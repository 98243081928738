import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from '../components/seo';
import Title from '../components/title';
import Header from '../components/header';
import Container from '../components/container';
import FeatureBlock from '../components/feature-block';
import Layout from '../layout';
import Image from '../components/image';
import P from '../components/paragraph';
import AnimatedArticle from '../components/animated-article';
import AnimatedAside from '../components/animated-aside';

const EventsPage = () => {
  const images = useStaticQuery(graphql`
    query {
      milan: file(relativePath: { eq: "events/milan.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      berlin: file(relativePath: { eq: "events/berlin.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kuwait: file(relativePath: { eq: "events/kuwait.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      london: file(relativePath: { eq: "events/london.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      florence: file(relativePath: { eq: "events/florence.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cannes: file(relativePath: { eq: "events/cannes.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      chariot: file(relativePath: { eq: "events/chariot.png" }) {
        childImageSharp {
          fluid(maxWidth: 740) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title='Events'
        description={`
          The Valorem ethos is all about getting niche perfume brands to the
          right people. Our brands are showcased at global events, both industry
          and consumer focused.
        `}
      />

      <Header />

      <Container wide noPadding>
        <FeatureBlock>
          <AnimatedArticle onLoad>
            <Title fancy>Events</Title>
            <P noMargin='bottom'>
              The Valorem ethos is all about getting the brands to the right
              people. VDL revels in bringing products directly into the hands of
              both retailers and customers like. Showcasing at global events,
              both industry and consumer focused, we can build face to face
              relationships that can stand the test of time.
            </P>
          </AnimatedArticle>
          <AnimatedAside>
            <Image
              css={`
                @media (min-width: ${({ theme }) => theme.breakpoint.large}) {
                  margin-top: 100px;
                }
              `}
              fluid={images.chariot.childImageSharp.fluid}
              notch='bottomRight'
              alt='Boadicea Chariot'
            />
          </AnimatedAside>
        </FeatureBlock>
      </Container>

      <Container wide noPadding overflow>
        <FeatureBlock center grey={false}>
          <AnimatedAside right>
            <Image
              fluid={images.kuwait.childImageSharp.fluid}
              notch='topRight'
              alt='Distribution Services Kuwait Event'
            />
          </AnimatedAside>
          <AnimatedArticle>
            <Title from='20px' to='22px' tight>
              Perfumery and Cosmetic Exhibition
            </Title>
            <P small noMargin>
              KIF – Kuwait.
            </P>
            <P>
              Taking place over 10 days, twice a year, it is one of the major events in Kuwait. 
              With the blend of ancient oiled scents from the Arabian Gulf to modern cosmetic brands and everything else in-between. 
              Over 100 brands participate in the event and people will travel from all over the gulf to attend, discover and purchase new and bestselling products.
            </P>
          </AnimatedArticle>
        </FeatureBlock>
      </Container>

      <Container wide noPadding>
        <FeatureBlock center>
          <AnimatedArticle>
            <Title from='20px' to='22px' tight>
              Polo in the Park
            </Title>
            <P small noMargin>
              Hurlingham Park, London
            </P>
            <P>
              A three day polo event held in the heart of London, the ultimate start to the summer season in the UK.  
              With only a handful of retailers exhibiting at this exclusive event, it provides the ideal touch point with the affluent domestic customer.  
              It also illuminates Valorems' championing of all things British. 
              Boadicea the Victorious is proud to have been the only fragrance brand to sponsor a team and occupy a retail stand.
            </P>
          </AnimatedArticle>
          <AnimatedAside>
            <Image
              fluid={images.london.childImageSharp.fluid}
              notch='topLeft'
              alt='Valorem London Polo in the Park 2019'
            />
          </AnimatedAside>
        </FeatureBlock>
      </Container>

      <Container wide noPadding>
        <FeatureBlock center grey={false}>
          <AnimatedAside right>
            <Image
              fluid={images.milan.childImageSharp.fluid}
              notch='topRight'
              alt='Valorem Distribution Milan Esxence'
            />
          </AnimatedAside>
          <AnimatedArticle>
            <Title from='20px' to='22px' tight m>
              Esxence
            </Title>
            <P small noMargin>
              Milan, Italy
            </P>
            <P>
              A leading international event dedicated to Artistic Perfumery attended by professionals, exhibitors, retailer, buyers and distributors.
              VDL takes a stand within the exhibition, as well as a private suite within a luxury hotel.  
              Showcasing the brands we proudly represent and meeting friends both old and new in a relaxing and sophisticated environment.
            </P>
          </AnimatedArticle>
        </FeatureBlock>
      </Container>

      <Container wide noPadding 
        css={`
        @media (min-width: ${({ theme }) => theme.breakpoint.large}) {
          margin-bottom: 100px;
        }
      `}>
        <FeatureBlock center>
          <AnimatedArticle>
            <Title from='20px' to='22px' tight m>
              TFWA
            </Title>
            <P small noMargin>
              Cannes, France
            </P>
            <P>
              There is no better place to be than in Cannes, absorbed in glamour and excess, it is the perfect location to illuminate the brands of the moment.
              Taking a private suite close to the TFWA show, Valorem once again showcase the brands they represent, taking meetings and inviting our most valued clients.
            </P>
          </AnimatedArticle>
          <AnimatedAside>
            <Image
              fluid={images.cannes.childImageSharp.fluid}
              notch='topLeft'
              alt='Valorem London Polo in the Park 2019'
            />
          </AnimatedAside>
        </FeatureBlock>
      </Container>

    </Layout>
  );
};

export default EventsPage;
